import React from "react";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";
import EditForm from './EditForm'
import { firestore } from "../../firebase";
import toast from 'react-hot-toast';

const CustomModal = ({ show, setShow, isEditing, isDeleting, data }) => {

  const deleteItem = async (id) => {
    try {
      const res = await firestore.collection("items").doc(id).delete()

      setShow(false)
      toast.success('Deleted successfully!')
      window.location.reload()
      console.log(res)
    } catch {
      setShow(false)
      toast.error('Error when deleting!')
    }
  }

  const { id, name } = data

  return (
    <>
      <Modal size="regular" active={show} toggler={() => setShow(false)}>
        <ModalHeader toggler={() => setShow(false)}>
          {isEditing && <>Editing {name}</>}
          {isDeleting && <>Are you sure you want to delete?</>}
        </ModalHeader>
        {isEditing &&
          <>
            <ModalBody>
              <EditForm id={id} initialData={data} setShow={setShow} />
            </ModalBody>
          </>
        }
        {isDeleting &&
          <>
            <ModalBody>
              Delete "{name}"?
            </ModalBody>
            <div className="flex content-center justify-around items-center">
              <Button
                color="pink"
                buttonType="link"
                onClick={() => setShow(false)}
                ripple="dark"
              >
                Close
              </Button>
              <Button type="submit" color="pink" onClick={() => { deleteItem(id) }}>
                Delete Item
              </Button>
            </div>
          </>
        }

      </Modal>
    </>
  );
}

export default CustomModal