import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import DefaultNavbar from 'components/DefaultNavbar';
import SimpleFooter from 'components/SimpleFooter';
import Page from 'components/login/Page';
import Icon from '@material-tailwind/react/Icon';
import imageUrl from '../assets/img/picture-2.jpg';

export default function About() {

    return (
        <Page>
            <DefaultNavbar />
            <div className="ml-5 mr-5">
                <Card>
                    <CardBody>
                        <section className="relative">
                            <div className="block sm:inline-flex md:block lg:flex xl:flex">
                                <div className="flex-1 text-gray-700 text-center bg-white px-4 py-2 m-2 justify-center">
                                    <p className="text-justify inline-block align-middle">
                                        <p>Track A Meal was started after I realized that my biggest struggle when it came to losing weight wasn't hitting the gym, but rather eating properly.</p><br></br>
                                        <p>I would always wonder not only what I should be eating after a proper gym session, but how much food should I be consuming? Passing by Wendy's, Wing Stop and so many other restaurants was for sure tempting, but also made me wonder. Do these fast food chains as well as sit-down restaurants have healthy options?</p><br></br>
                                        <p>That is how Track A Meal was born. To help you find healthier options at these major fast food chains to help you stay on track with your fitness goals.</p>
                                    </p>
                                </div>
                                <div className="flex-1 text-gray-700 text-center bg-white px-4 py-2 m-2">
                                    <img src={imageUrl} alt="" />
                                    <div className="flex items-center bg-gray-200 h-24">
                                        <div className="flex-1 text-gray-700 text-center px-4 py-2 m-2">
                                            <a
                                                href="https://www.linkedin.com/in/ibsaajadam"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Icon
                                                    family="font-awesome"
                                                    name="fab fa-linkedin"
                                                />
                                            </a>
                                        </div>
                                        <div className="flex-1 text-gray-700 text-center px-4 py-2 m-2">
                                            <a
                                                href="https://www.github.com/ibsaajadam"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Icon
                                                    family="font-awesome"
                                                    name="fab fa-github"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </CardBody>
                </Card>
            </div>
            <SimpleFooter />
        </Page>
    );
}
