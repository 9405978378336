import React, { useState } from 'react';

import { firebaseRef, firestore, storage } from '../../firebase';
import AuthService from '../../services/auth.service';
import { useForm, Controller } from "react-hook-form";

import Small from "@material-tailwind/react/Small";
import Heading6 from "@material-tailwind/react/Heading6";
import CreatableSelect from 'react-select/creatable';
import { Input, Button } from '@material-tailwind/react';
import { ErrorMessage } from "@hookform/error-message";

import toast from 'react-hot-toast';

const Form = ({ restaurant }) => {

  const [ingredients, setIngredients] = useState([])
  const [category, setCategory] = useState('')
  const [inputSelect, setInputSelect] = useState('')
  const [inputMultiSelect, setInputMultiSelect] = useState('')

  const [file, setFile] = useState(null)
  const [fileUrl, setFileUrl] = useState(null)

  const { control, handleSubmit, formState: { errors } } = useForm();

  const fileChange = (e) => {
    setFile(e.target.files[0])
  }

  const upload = () => {
    if (file === null) {
      toast.error('Please, choose a file to upload.')
      return
    }

    const loading = toast.loading('Uploading image...');
    const storageRef = storage.ref();
    const uploadTask = storageRef.child('image/' + file.name).put(file);
    uploadTask.on(firebaseRef.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
      }, (error) => {
        toast.error('Error while uploading.');
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setFileUrl(url);
          toast.dismiss(loading)
        })
      }
    )
  }

  const onSubmit = (data) => {
    const { value } = restaurant;
    const user = AuthService.getCurrentUser();

    firestore.collection("items").add({
      ...data,
      imageUrl: fileUrl,
      restaurantId: value,
      userId: user.uid,
      category: { value: category.value, label: category.label },
      ingredients,
      created: firebaseRef.firestore.Timestamp.fromDate(new Date())
    }).then((docRef) => {
      toast.success('Item created!');
    }).catch((error) => {
      toast.error('Failed to create item!');
    });
  }


  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleTagChange = (value) => {
    setIngredients(value);
  }

  const handleTagInputChange = (value) => {
    setInputMultiSelect(value)
  }

  const handleKeyDown = (event) => {
    if (!inputMultiSelect) {
      return;
    }
    switch (event.key) {
      case ',':
        setInputMultiSelect('')
        setIngredients(prevState => [...prevState, createOption(inputMultiSelect)])
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleCatChange = (value) => {
    setCategory(value);
  }

  const handleCatInputChange = (value) => {
    setInputSelect(value)
  }

  return (
    <form className="w-full space-y-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="lg:grid lg:grid-cols-3 lg:gap-5 space-y-5">
        <div className="lg:col-span-1">
          <Heading6>
            Category
          </Heading6>
          <CreatableSelect
            inputValue={inputSelect}
            onChange={handleCatChange}
            onInputChange={handleCatInputChange}
            placeholder="Put the food category."
            value={category}
          />
        </div>
      </div>
      <div className="lg:grid lg:grid-cols-3 lg:gap-5 space-y-5">
        <div className="lg:col-span-1">
          <Heading6>
            Ingredients
          </Heading6>
          <CreatableSelect
            inputValue={inputMultiSelect}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleTagChange}
            onInputChange={handleTagInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Separate ingredients with a comma..."
            value={ingredients}
          />
        </div>
        <div className="lg:col-span-1">
          <Heading6>Add item image</Heading6>
          <div className="space-y-5 lg:space-y-0 lg:flex content-center items-center">
            <input type="file" className="w-full" name="image" onChange={(e) => { fileChange(e) }} />
            <Button size="sm" className="w-full lg:w-auto" type="button" color="pink" onClick={upload}>Upload image</Button>
          </div>
        </div>
        <div className="lg:col-span-1">
          {fileUrl !== null && <img alt="Preview" className="rounded shadow-md object-fit w-full h-full" src={fileUrl} />}
        </div>
      </div>
      <div className="space-y-5 lg:space-y-0 lg:grid lg:grid-cols-3 gap-5">
        <div className="col-span-1">
          <Heading6>
            Item name
          </Heading6>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
            rules={{ required: 'This field is required!' }}
          />
          <Small color="red">
            <ErrorMessage errors={errors} name="name" as="span" />
          </Small>
        </div>
        <div className="col-span-1">
          <Heading6>
            Total calories
          </Heading6>
          <Controller
            name="totalCalories"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type="number" {...field} />}
            rules={{ required: 'This field is required!' }}
          />
          <Small color="red">
            <ErrorMessage errors={errors} name="totalCalories" as="span" />
          </Small>
        </div>
        <div className="col-span-1">
          <Heading6>
            Serving size
          </Heading6>
          <Controller
            name="servingSize"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type="text" {...field} />}
            rules={{ required: 'This field is required!' }}

          />
          <Small color="red">
            <ErrorMessage errors={errors} name="servingSize" as="span" />
          </Small>
        </div>
        <div className="col-span-1">
          <Heading6>
            Total fat
          </Heading6>
          <Controller
            name="fat"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type="number" {...field} />}
            rules={{ required: 'This field is required!' }}

          />
          <Small color="red">
            <ErrorMessage errors={errors} name="fat" as="span" />
          </Small>
        </div>
        <div className="col-span-1">
          <Heading6>
            Total Carbohydrates
          </Heading6>
          <Controller
            name="carbo"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type="number" {...field} />}
            rules={{ required: 'This field is required!' }}

          />
          <Small color="red">
            <ErrorMessage errors={errors} name="carbo" as="span" />
          </Small>
        </div>
        <div className="col-span-1">
          <Heading6>
            Total Protein
          </Heading6>
          <Controller
            name="protein"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type="number" {...field} />}
            rules={{ required: 'This field is required!' }}
          />
          <Small color="red">
            <ErrorMessage errors={errors} name="protein" as="span" />
          </Small>
        </div>
        <div className="col-span-1">
          <Heading6>
            Total sodium
          </Heading6>
          <Controller
            name="sodium"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type="number" {...field} />}
          />
        </div>
        <div className="col-span-1">
          <Heading6>
            Total sugar
          </Heading6>
          <Controller
            name="sugar"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type="number" {...field} />}
          />
        </div>
        <div className="col-span-1">
          <Heading6>
            Total Cholesterol
          </Heading6>
          <Controller
            name="cholesterol"
            control={control}
            defaultValue=""
            render={({ field }) => <Input type="number" {...field} />}
          />
        </div>
        <div className="col-span-1">
        </div>
        <div className="flex justify-center">
          <Button size="lg" type="submit" className="place-center" color="pink">
            Submit Item
          </Button>
        </div>
      </div>
    </form>
  );
}

export default Form;
