import { get, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import ItemDetailsCard from './ItemDetailsCard';
import { Link } from 'react-router-dom'

import { Heading3 } from '@material-tailwind/react';
import { Helmet } from 'react-helmet';

const Content = ({ id }) => {
  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [restaurantName, setRestaurantName] = useState(null);

  const fetchItemDetails = () => {
    setLoading(true);
    firestore.collection("items").doc(id).get().then(snapshot => {
      if (snapshot.data()) {
        setItemDetails(snapshot.data());
        const restaurantId = get(snapshot.data(), 'restaurantId', null);
        fetchRestaurantName(restaurantId);
        setLoading(false);
      } else {
        setItemDetails(null);
        setLoading(false);
      }
    }).catch(err => {
      console.log('get restaurants error +++', err);
      setLoading(false);
    });
  }

  const fetchRestaurantName = (restaurantId) => {
    if (restaurantId) {
      firestore.collection("restaurants").doc(restaurantId).get().then(snapshot => {
        if (snapshot.data()) {
          setRestaurantName(get(snapshot.data(), 'restaurant', null));
        } else {
          setRestaurantName(null);
        }
      }).catch(err => {
        console.log('get restaurant name error +++', err);
      });
    }
  }

  useEffect(() => {
    fetchItemDetails();
    // eslint-disable-next-line
  }, [])

  const itemName = get(itemDetails, 'name', null);

  return (
    <section className="pt-20 pb-48">
      <Helmet>
        <title>{restaurantName + ' ' + itemName + ' ' + 'calories'}</title>
        <meta name='description' content={restaurantName + ' ' + 'nutritional information and calorie counter'}  />
        <meta name='keywords' content={restaurantName + ',' + 'calories' + ',' + 'nutritional information' + ',' + 'weight loss' + ',' + 'diet' + ',' + 'calorie counting' + ',' + 'calorie tracker' + ',' + 'diet app' + ',' + 'diet website' + ',' + 'calorie counter' + ',' + 'calorie counter website'} />
      </Helmet>
      <div className="container max-w-7xl mx-auto px-4">
        <div className="lg:flex-col lg:space-x-2 space-y-5 lg:space-y-0">
          <div className="w-full flex flex-col lg:flex-row justify-between items-center py-5 lg:py-5">
            <Heading3 className="order-2 lg:order-1">
              <Link to={`/restaurant/${itemDetails?.restaurantId}`}>
                <span className="text-base underline">Go Back</span> | <span className="text-xl">{itemName}</span>
              </Link>
            </Heading3>
            <div className="flex space-x-2 items-center order-1 lg:order-2 lg:ml-auto">
              {restaurantName && (
                <Heading3>
                  {restaurantName}
                </Heading3>
              )}
            </div>
          </div>
          {loading &&
            <div className="w-full flex flex-col content-center items-center justify-center py-5 space-y-5">
              <i className="fas fa-spinner fa-2x fa-spin"></i>
              <p className="text-2xl">
                Loading...
              </p>
            </div>
          }
          <div className="">
            {!isEmpty(itemDetails) &&
              <>
                <ItemDetailsCard
                  name={itemDetails.name}
                  category={itemDetails.category}
                  servingSize={itemDetails.servingSize}
                  totalCalories={itemDetails.totalCalories}
                  totalFat={itemDetails.fat}
                  totalCarbo={itemDetails.carbo}
                  totalProtein={itemDetails.protein}
                  totalSodium={itemDetails.sodium}
                  totalSugar={itemDetails.sugar}
                  imageUrl={itemDetails.imageUrl}
                  totalCholesterol={itemDetails.cholesterol}
                  ingredients={itemDetails.ingredients}
                />
              </>
            }
            {isEmpty(itemDetails) && itemDetails !== null &&
              <div className="w-full lg:col-span-12 text-center">
                No item details found.
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;