import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Content from 'components/restaurantMenu/Content';
import { useParams } from 'react-router-dom';


const RestaurantMenu = () => {
  const { id } = useParams()
  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Content id={id} />
      </main>
      <DefaultFooter />
    </>
  );
}

export default RestaurantMenu