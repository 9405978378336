import { firestore } from '../../firebase';
import AsyncSelect from 'react-select/async';

import Heading5 from "@material-tailwind/react/Heading5";
import Button from "@material-tailwind/react/Button";
import NavLink from '@material-tailwind/react/NavLink';


import { useState } from 'react';
import { filter, get } from 'lodash'
import Form from './Form';
import { Heading4 } from '@material-tailwind/react';
import toast from 'react-hot-toast';

export default function Content () {
  const [restaurant, setRestaurant] = useState({})

  const fetchRestaurants = async (value) => {
    return firestore.collection("restaurants").get()
      .then(snapshot => {
        let docs = [];
        if (snapshot.size) {
          snapshot.forEach(doc => {
            const mapResponseToValuesAndLabels = (doc) => ({
              value: doc.id,
              label: doc.data().restaurant,
            });

            docs.push(mapResponseToValuesAndLabels(doc))
          });

          return filter(docs, d => d.label.toLowerCase().includes(value.toLowerCase()))
        }
      })
      .catch(err => {
        toast.error("Error in fetch restaurants!");
      });
  }

  return (
    <section className="pt-20 pb-48">
      <div className="container max-w-7xl mx-auto px-4">
        <div className="flex justify-center">
          <Heading4>Add Menu Item</Heading4>
        </div>
        <div className="relative mx-auto flex w-full justify-between flex-wrap items-stretch mb-3">
          <span
            className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
          </span>
          <div className="w-full flex-col lg:flex items-center justify-center">
            <AsyncSelect
              className="w-full lg:w-1/3"
              placeholder="Type the restaurant name..."
              cacheOptions
              loadOptions={fetchRestaurants}
              onChange={(data) => {
                setRestaurant(data);
              }}
              defaultOptions />

            <div className="mx-auto flex justify-center w-full">

              <NavLink
                rel="noreferrer"
                href="/restaurant"
              >
                <Button color="pink" className="w-full">
                  Add new restaurant
                </Button>
              </NavLink>
            </div>
          </div>
          {get(restaurant, 'value', null) ?
            <>
              <Heading5>
                <span className="text-gray-600 mr-2">
                  Chosen restaurant:
                </span>
                {restaurant.label}
              </Heading5>
              <div className="w-full flex">
                <div className="p-5 lg:p-10 rounded-lg shadow-lg border-opacity-20 border-gray-800 border">
                  <Form restaurant={restaurant} />
                </div>
              </div>
            </>
            :
            <><div className="w-full text-center my-5">Please, select a restaurant</div></>
          }
        </div>
      </div>
    </section >
  );
}
