import Icon from '@material-tailwind/react/Icon';

import { get, isEmpty, filter, map, sortBy, reverse, uniq } from 'lodash';
import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import ItemCard from './ItemCard';
// import GoogleAds from '../google-ads/GoogleAds';
import AdSense from 'react-adsense';
import AuthService from '../../services/auth.service';

import { Button } from '@material-tailwind/react';
import { Heading3, Input } from '@material-tailwind/react';
import { Helmet } from 'react-helmet';

const Content = ({ id }) => {
  const [data, setData] = useState([])
  // const [categoryData, setcategoryData] = useState([])
  const [restaurantInfo, setRestaurantInfo] = useState()

  const [sort, setSort] = useState('name')
  const [order, setOrder] = useState('asc')
  const [search, setSearch] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')

  const [loading, setLoading] = useState(false)

  const handleSearchBarChange = (e) => {
    setSearch(e.target.value);
  }

  const fetchItems = () => {
    setLoading(true)
    firestore.collection("restaurants").doc(id).get().then(snapshot => {
      if (snapshot.data()) {
        setRestaurantInfo(snapshot.data());
        setLoading(false);
      } else {
        setRestaurantInfo([]);
        setLoading(false);
      }
    })
      .catch(err => {
        console.log('get restaurants error +++', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    firestore.collection("items").where('restaurantId', '==', id).get().then(snapshot => {
      if (snapshot.size) {
        let temp = [];
        snapshot.forEach(doc => {
          // setData(data => [...data, { rid: doc.id, cat: '', ...doc.data(), ingredients: { ...doc.data().ingredients } }]);
          temp.push({ rid: doc.id, cat: '', ...doc.data(), ingredients: { ...doc.data().ingredients } });
        });
        setData(temp);
      } else {
        console.log('no have restaurants +++');
        setData([]);
      }
    })
      .catch(err => {
        console.log('get restaurants error +++', err);
      });

    // firestore.collection("category").get().then(snapshot => {
    //   if (snapshot.size) {
    //     snapshot.forEach(doc => {
    //       setcategoryData(data => [...data, { cid: doc.id, ...doc.data() }]);
    //       setLoading(false)
    //     });
    //   } else {
    //     console.log('no have categories');
    //     setLoading(false)
    //     setcategoryData([]);
    //   }
    // })
    //   .catch(err => {
    //     console.log(err);
    //   });
  }, [id]);

  const toggleOrder = () => {
    order === 'asc' ? setOrder('desc') : setOrder('asc')
  };

  const user = AuthService.getCurrentUser();

  const uid = get(user, 'uid', null);

  const restaurantName = get(restaurantInfo, 'restaurant', null);

  const sortedData = order === 'asc' ? reverse(sortBy(data, (e) => {
    if (sort === 'name') {
      return e.name;
    } else {
      return Number(get(e, sort));
    }
  })) : sortBy(data, (e) => {
    if (sort === 'name') {
      return e.name;
    } else {
      return Number(get(e, sort));
    }
  });

  let filteredData = [];

  if (selectedCategory === '') {
    filteredData = filter(sortedData, d => (
      d.name.toLowerCase().includes(search.toLowerCase())));
  } else {
    filteredData = filter(sortedData, d => (
      d.name.toLowerCase().includes(search.toLowerCase()) && d.category.value.includes(selectedCategory)));
  }
  console.log('sortedData +++', sortedData);
  const categories = uniq(map(sortedData, i => { if (i !== '') { return i.category.value } }));
  const uniqCategories = Array.from(new Set(categories.map(JSON.stringify))).map(JSON.parse);

  return (
    <section className="pt-20 pb-48">
      <Helmet>
        <title>{restaurantName + ' ' + 'nutrition'}</title>
        <meta name='description' content={restaurantName + ' ' + 'nutritional information and calorie counter'}  />
        <meta name='keywords' content={restaurantName + ',' + 'calories' + ',' + 'nutritional information' + ',' + 'weight loss' + ',' + 'diet' + ',' + 'calorie counting' + ',' + 'calorie tracker' + ',' + 'diet app' + ',' + 'diet website' + ',' + 'calorie counter' + ',' + 'calorie counter website'} />
      </Helmet>
      <div className="container max-w-7xl mx-auto px-4">
        <div className="lg:flex lg:space-x-2 space-y-5 lg:space-y-0">
          <div className="flex items-center space-x-2 lg:flex lg:items-center lg:space-x-2">
            <select
              className="w-64 lg:w-auto px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring ring-pink-100"
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="" selected>Filter by category</option>
              {/* {
                categoryData.map((singlecategory) => {
                  return (
                    <option value={singlecategory.name}>{singlecategory.name}</option>
                  )
                })
              } */}
              {map(uniqCategories, c => { return <option key={c} value={c}>{c}</option> })}
            </select>
            <select
              className="w-64 lg:w-auto px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring ring-pink-100"
              onChange={(e) => setSort(e.target.value)}
            >
              <option value="name">Name</option>
              <option value="totalCalories">Total Calories</option>
              <option value="fat">Total Fat</option>
              <option value="carbo">Total Carbohydrates</option>
              <option value="protein">Total Protein</option>
              <option value="sodium">Total Sodium</option>
              <option value="sugar">Total Sugar</option>
              <option value="cholesterol">Total Cholesterol</option>
            </select>
            <Button
              color="pink"
              buttonType="filled"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={true}
              ripple="light"
              onClick={toggleOrder}
            >
              <Icon family="font-awesome"
                name={`${order === 'asc' ? 'fas fa-arrow-up' : 'fas fa-arrow-down'}`}
              />
            </Button>
          </div>
          <Input
            type="text"
            outline={true}
            placeholder="Search menu items"
            id="search"
            name="search"
            onChange={(e) => handleSearchBarChange(e)}
            className="placeholder-gray-400 text-gray-600 relative bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring pl-10"
            color="pink"
          />
        </div>
        <div className="w-full flex justify-center lg:justify-end py-5 lg:py-5">
          <div className="flex space-x-2 items-center">
            <Heading3>
              {restaurantName}
            </Heading3>
          </div>
        </div>
        {loading &&
          <div className="w-full flex flex-col content-center items-center justify-center py-5 space-y-5">
            <i className="fas fa-spinner fa-2x fa-spin"></i>
            <p className="text-2xl">
              Loading...
            </p>
          </div>
        }
        <div className="grid lg:grid-cols-12">
          <div className="google-ads-container lg:col-span-3">
            {/* <h3>Testing ads</h3> */}
            {/* <GoogleAds idSlot="8509985184" /> */}
            <AdSense.Google
              client='ca-pub-6055384758378838'
              slot='8509985184'
            />
          </div>
          <div className="grid grid-cols-1 lg:col-span-9 lg:grid-cols-3 gap-5">
            {!loading && !isEmpty(filteredData) && map(filteredData, (ele) => {
              return (
                <>
                  <ItemCard
                    id={ele.rid}
                    name={ele.name}
                    servingSize={ele.servingSize}
                    totalCalories={ele.totalCalories}
                    totalFat={ele.fat}
                    totalCarbo={ele.carbo}
                    totalProtein={ele.protein}
                    totalSodium={ele.sodium}
                    totalSugar={ele.sugar}
                    imageUrl={ele.imageUrl}
                    totalCholesterol={ele.cholesterol}
                    ingredients={ele.ingredients}
                    category={ele.category}
                    isOwner={ele.userId === uid}
                    key={ele.rid}
                  />
                </>
              )
            }
            )}
            {!loading && isEmpty(filteredData) &&
              <div className="w-full lg:col-span-12 text-center">
                No items found.
              </div>
            }
          </div>
        </div>
      </div>
    </section >
  );
}

export default Content;