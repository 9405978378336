import Button from '@material-tailwind/react/Button';
import Input from '@material-tailwind/react/Input';
import { useState } from 'react';
import { firebaseRef, storage, firestore } from '../../firebase';
import AuthService from '../../services/auth.service';
import { Heading3 } from '@material-tailwind/react';
import { useHistory } from "react-router-dom";
import toast from 'react-hot-toast';

export default function Content () {

  const [restaurant, setRestaurant] = useState("");
  const [image, setImage] = useState(null);
  const [downloadURL, setDownloadURL] = useState(null);

  const history = useHistory();

  function onChangeHandler (event) {
    const { name, value } = event.currentTarget;
    if (name === "restaurant") {
      setRestaurant(value);
    } else if (name === "image") {
      setImage(event.target.files[0]);
    }
  }

  function handleUpload () {
    const loading = toast.loading('Uploading image...');
    const storageRef = storage.ref();
    const uploadTask = storageRef.child('image/' + image.name).put(image);
    uploadTask.on(firebaseRef.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
      }, (error) => {
        toast.error('Error while uploading.');
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          toast.success('Upload successfull!');
          setDownloadURL(url);
          toast.dismiss(loading)
        })
      }
    )
  }


  function addNew () {
    const user = AuthService.getCurrentUser();
    firestore.collection("restaurants").add({
      uid: user.uid,
      restaurant,
      downloadURL,
      created: firebaseRef.firestore.Timestamp.fromDate(new Date())
    }).then((docRef) => {
      console.log("restaurant created with this id +++", docRef.id);
      setRestaurant("");
      setDownloadURL(null);
      setImage(null);
      document.getElementById("image").value = null;
      toast.success('Restaurant created!');
      history.push('/add');
    }).catch((error) => {
      console.error("restaurant create error +++", error);
      toast.error('Failed to create item!');
    });
  }

  return (
    <section className="relative py-16 bg-gray-100">
      <div className="container max-w-7xl px-4 mx-auto">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
          <div className="px-6 flex flex-col space-y-5">
            <div className="text-center w-full py-5">
              <Heading3>Create new restaurant</Heading3>
            </div>
            <div className="w-full lg:flex-col items-center">
              <div className="space-y-5 lg:space-y-0 lg:flex lg:space-x-10">
                <div className="w-full lg:w-1/2 space-y-5">
                  <Input
                    type="text"
                    color="lightBlue"
                    size="lg"
                    outline={false}
                    placeholder="Restaurant Name"
                    name="restaurant"
                    id="restaurant"
                    value={restaurant}
                    onChange={(event) => onChangeHandler(event)}
                  />
                </div>
                <div className="lg:w-1/2 flex-col justify-center align-center space-y-5">
                  <div className="lg:col-span-1 flex justify-center">
                    {downloadURL !== null && <img alt="Preview" width={256} height={256} className="rounded shadow-md object-fit" src={downloadURL} />}
                  </div>
                  <Input
                    type="file"
                    color="lightBlue"
                    size="lg"
                    outline={false}
                    name="image"
                    id="image"
                    onChange={(event) => onChangeHandler(event)}
                  />
                  <div className="w-full flex justify-center py-5">
                    <Button
                      color="lightBlue"
                      buttonType="filled"
                      size="lg"
                      rounded={true}
                      block={false}
                      iconOnly={false}
                      ripple="light"
                      onClick={(event) => { handleUpload(event) }}
                      disabled={!image}
                    >
                      Upload Image
                    </Button>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-center py-5">
                <Button
                  color="pink"
                  buttonType="filled"
                  size="lg"
                  rounded={true}
                  ripple="light"
                  onClick={(event) => { addNew(event) }}
                  disabled={!restaurant || !downloadURL}
                >
                  Add Restaurant
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
}
