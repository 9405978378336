import Title from 'components/landing/Title';
import H5 from '@material-tailwind/react/Heading5';
import { useEffect, useState } from 'react';
import { firestore } from '../../firebase';
// import AuthService from '../../services/auth.service';
import { filter, map, orderBy } from 'lodash'
import { Link } from 'react-router-dom';

export default function RestaurantSection () {

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("");

  useEffect(() => {
    setLoading(true)
    firestore.collection("restaurants").get().then(snapshot => {
      if (snapshot.size) {
        snapshot.forEach(doc => {
          setData(data => [...data, { rid: doc.id, ...doc.data() }]);
          setLoading(false)
        });
      } else {
        console.log('no have restaurants +++');
        setLoading(false)
        setData([]);
      }
    })
      .catch(err => {
        console.log('get restaurants error +++', err);
      });
  }, [])

  function handleChange (event) {
    setSearch(event.target.value);
    console.log(data)
  }

  return (
    <section className="pt-20 pb-48">
      <div className="container max-w-7xl mx-auto px-4">
        <Title heading="Explore Our Restaurants">
        Check out our list of restaurants to stay on track with your fitness goals without having to give up your favorite foods.
        </Title>
        <div className="relative space-y-5 lg:space-y-0 mx-auto flex w-full justify-between flex-wrap items-stretch mb-3">
          <select
            className="w-full lg:w-48 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring lg:mr-10"
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="asc">Name (A-Z)</option>
            <option value="desc">Name (Z-A)</option>
          </select>
          <input
            type="text"
            placeholder="Search"
            id="search"
            name="search"
            onChange={(event) => handleChange(event)}
            className="w-full px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:ring lg:w-4/5 lg:pl-10" />
        </div>
        {loading &&
          <div className="w-full flex flex-col content-center items-center justify-center py-5 space-y-5">
            <i className="fas fa-spinner fa-2x fa-spin"></i>
            <p className="text-2xl">
              Loading...
            </p>
          </div>}
        <div className="container mx-auto space-y-2 lg:space-y-0 lg:gap-2 lg:grid lg:grid-cols-3">
          {!loading && map(filter(orderBy(data, ['restaurant'], sort),
            d => (d.restaurant.toLowerCase().includes(search.toLowerCase()))), (ele) => {
              return (
                <div key={ele.rid} className="w-full rounded shadow-2xl pt-10 text-center sm:text-center md:text-center lg:text-center xl:text-center">
                  <Link to={`/restaurant/${ele.rid}`}>
                    <img
                      className="rounded shadow-md object-fit w-full h-64"
                      src={ele.downloadURL}
                      key={ele.rid}
                      alt={ele.restaurant} />
                    <H5 key={ele.rid + 'h5'}>{ele.restaurant}</H5>
                  </Link>
                </div>
              )
            }
          )}
        </div>
      </div>
    </section >
  );
}
