import React from 'react';

import { firestore } from '../../firebase';
// import AuthService from '../../services/auth.service';
import { useForm, Controller } from "react-hook-form";

import Small from "@material-tailwind/react/Small";
import Heading6 from "@material-tailwind/react/Heading6";
// import CreatableSelect from 'react-select/creatable';
import { Input, Button } from '@material-tailwind/react';
import { ErrorMessage } from "@hookform/error-message";

import toast from 'react-hot-toast';

const Form = () => {
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    // console.log(data.name)
    // const user = AuthService.getCurrentUser();

    firestore.collection("category").add({
      name: data.name
    }).then((docRef) => {
      toast.success('Category added successfully');
      setTimeout(() => {
        window.location.href = '/categories';
      }, 1000);
    }).catch((error) => {
      toast.error('Failed to create category!');
    });
  }



  return (
    <form className="w-full space-y-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-5 lg:space-y-0 lg:grid lg:grid-cols-3 gap-5">
        <div className="col-span-3">
          <Heading6>
            Category Name
          </Heading6>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
            rules={{ required: 'This field is required!' }}
          />
          <Small color="red">
            <ErrorMessage errors={errors} name="name" as="span" />
          </Small>
        </div>
        <div className="col-span-1">
        </div>
        <div className="flex justify-center">
          <Button size="lg" type="submit" className="place-center" color="pink">
            Create Category
          </Button>
        </div>
      </div>
    </form>
  );
}

export default Form;
