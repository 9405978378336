import { useEffect, useState } from 'react';
import { firestore } from '../../firebase';
import { Button } from '@material-tailwind/react';
import CustomModal from './CustomModal'

function CategoryList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [categoryId, setCategoryId] = useState(null)
    const [categoryName, setCategoryName] = useState("");

    useEffect(() => {
        setLoading(true)
        firestore.collection("category").get().then(snapshot => {
            if (snapshot.size) {
              snapshot.forEach(doc => {
                setData(data => [...data, { cid: doc.id, ...doc.data() }]);
                setLoading(false)
              });
            } else {
              console.log('no have categories');
              setLoading(false)
              setData([]);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }, [])
    
    return (
        <div>
            <CustomModal show={show} setShow={setShow} isEditing={isEditing} isDeleting={isDeleting} categoryId={categoryId} data={categoryName} />
            {loading &&
                <div className="w-full flex flex-col content-center items-center justify-center py-5 space-y-5">
                    <i className="fas fa-spinner fa-2x fa-spin"></i>
                    <p className="text-2xl">
                    Loading...
                    </p>
                </div>
            }
            {
                !loading && 
                    <div>
                        <table class="shadow-lg bg-white border-collapse">
                            <thead>
                                <tr>
                                    <th class="bg-blue-100 border text-left px-8 py-4">Category Name</th>
                                    <th class="bg-blue-100 border text-left px-8 py-4"></th>
                                    <th class="bg-blue-100 border text-left px-8 py-4"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((singledata) => {
                                        return (
                                            <tr key={ singledata.cid }>
                                                <td class="border px-8 py-4">{ singledata.name }</td>
                                                <td class="border px-8 py-4">
                                                    <Button
                                                        color="orange"
                                                        buttonType="filled"
                                                        size="regular"
                                                        rounded={false}
                                                        block={false}
                                                        ripple="light"
                                                        onClick={() => { setIsDeleting(false); setIsEditing(true); setShow(true); setCategoryId(singledata.cid); setCategoryName(singledata.name); }}
                                                        >
                                                            Edit Category
                                                    </Button>
                                                </td>
                                                <td class="border px-8 py-4">
                                                    <Button
                                                        color="red"
                                                        buttonType="filled"
                                                        size="regular"
                                                        rounded={false}
                                                        block={false}
                                                        ripple="light"
                                                        onClick={() => { setIsDeleting(true); setIsEditing(false); setShow(true); setCategoryId(singledata.cid); }}
                                                        >
                                                            Delete Category
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                }
        </div>
    )
}

export default CategoryList;