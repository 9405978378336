import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import ViewCategoryContent from 'components/category/ViewCategoryContent';

export default function Categories() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <ViewCategoryContent />
            </main>
            <DefaultFooter />
        </>
    )
}