import React, { useRef } from 'react';

import Card from "@material-tailwind/react/Card";
import CardImage from "@material-tailwind/react/CardImage";
import CardBody from "@material-tailwind/react/CardBody";
import H6 from "@material-tailwind/react/Heading6";
import Paragraph from "@material-tailwind/react/Paragraph";
import Popover from "@material-tailwind/react/Popover";
import PopoverContainer from "@material-tailwind/react/PopoverContainer";
import PopoverHeader from "@material-tailwind/react/PopoverHeader";
import PopoverBody from "@material-tailwind/react/PopoverBody";
import Button from "@material-tailwind/react/Button";


import { map, size } from 'lodash';

const ItemDetailsCard = ({
  id,
  category,
  name,
  servingSize,
  totalCalories,
  totalFat,
  totalCarbo,
  totalProtein,
  imageUrl,
  ingredients,
  totalSodium = null,
  totalSugar = null,
  totalCholesterol = null }) => {

  const buttonRef = useRef();

  return (
    <div className="py-5 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 mx-auto sm:w-4/5 md:w-4/5 lg:w-4/5">
      <Card>
        <div>
          {
            imageUrl === null &&
            <CardImage
              src={'/placeholder.png'}
              alt="Card Image"
              // className="h-96"
            />
          }
          {
            imageUrl !== null &&
            <CardImage
              src={imageUrl}
              alt="Card Image"
              // className="h-auto pr-20 pl-20 pt-10 pb-10"
            />
          }
          <div
            className="flex space-x-2 content-center justify-end py-2"
          >
            <Button
              color="pink"
              buttonType="filled"
              size="lg"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="light"
              ref={buttonRef}
              className={`w-full ${size(ingredients) === 0 ? 'cursor-not-allowed' : ''}`}
            >
              Ingredients
            </Button>
            <Popover placement="bottom" className="w-full h-full pl-5" ref={buttonRef}>
              <PopoverContainer>
                <PopoverHeader>Ingredients</PopoverHeader>
                <PopoverBody>
                  {map(ingredients, i => <p className="capitalize">{i.value}</p>)}
                  {size(ingredients) === 0 && <p> No ingredients found</p>}
                </PopoverBody>
              </PopoverContainer>
            </Popover>
          </div>
        </div>
        <CardBody>
          <H6 color="gray">{name}</H6>
          <Paragraph color="gray">
            Serving size: {servingSize}
          </Paragraph >
          <Paragraph color="gray">
            Category: {category.value}
          </Paragraph >
          <Paragraph color="gray">
            Total Calories: {totalCalories}
          </Paragraph >
          <Paragraph color="gray">
            Total Fat (g): {totalFat}
          </Paragraph >
          <Paragraph color="gray">
            Total Carbs (g): {totalCarbo}
          </Paragraph >
          <Paragraph color="gray">
            Total Protein (g): {totalProtein}
          </Paragraph >
          <Paragraph color="gray">
            Total Sodium (mg): {totalSodium}
          </Paragraph >
          <Paragraph color="gray">
            Total Sugar (g): {totalSugar}
          </Paragraph >
          <Paragraph color="gray">
            Total Cholesterol (mg): {totalCholesterol}
          </Paragraph >
        </CardBody>

      </Card >
    </div >
  );
}

export default ItemDetailsCard;