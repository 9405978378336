import { useState } from 'react';
import Navbar from '@material-tailwind/react/Navbar';
import NavbarContainer from '@material-tailwind/react/NavbarContainer';
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper';
import NavbarBrand from '@material-tailwind/react/NavbarBrand';
import NavbarToggler from '@material-tailwind/react/NavbarToggler';
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse';
import Nav from '@material-tailwind/react/Nav';
import NavLink from '@material-tailwind/react/NavLink';
import AuthService from '../services/auth.service';
import { auth } from '../firebase.js';

export default function DefaultNavbar() {
  const [openNavbar, setOpenNavbar] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState("Ibsaa");
  const user = AuthService.getCurrentUser();
  console.log(user)

  function logOut() {
    auth.signOut().then().catch();
    AuthService.logout();
  }

  return (
    <Navbar color="pink" navbar>
      <NavbarContainer>
        <NavbarWrapper>
          <NavbarBrand><a href='/'>Track A Meal</a></NavbarBrand>
          <NavbarToggler
            color="white"
            onClick={() => setOpenNavbar(!openNavbar)}
            ripple="light"
          />
        </NavbarWrapper>

        <NavbarCollapse open={openNavbar}>
          <Nav>
            <div className="flex flex-col z-50 lg:flex-row lg:items-center">
              {user ? (
                <>
                  <NavLink
                    rel="noreferrer"
                    ripple="light"
                  >
                    Welcome back, {name}
                  </NavLink>
                  <NavLink
                    href="/restaurant"
                    rel="noreferrer"
                    ripple="light"
                  >
                    Restaurant
                  </NavLink>
                  <NavLink
                    href="/add"
                    rel="noreferrer"
                    ripple="light"
                  >
                    Items
                  </NavLink>
                  <NavLink
                    href="/admin/login"
                    rel="noreferrer"
                    ripple="light"
                    onClick={logOut}
                  >
                    LogOut
                  </NavLink>
                </>
              ) : (
                <>
                </>
              )
              }
              <NavLink
                href="/about"
                rel="noreferrer"
                ripple="light"
              >
                About
              </NavLink>
              <NavLink
                href="/contact"
                rel="noreferrer"
                ripple="light"
              >
                Contact
              </NavLink>
            </div>
          </Nav>
        </NavbarCollapse>
      </NavbarContainer>
    </Navbar>
  );
}
