import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import RestaurantSection from 'components/landing/RestaurantSection';

export default function Landing() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <RestaurantSection />
            </main>
            <DefaultFooter />
        </>
    );
}
