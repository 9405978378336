import React, { useState, useEffect } from 'react';

import { firestore, firebaseRef, storage } from '../../firebase';

import { useForm, Controller } from "react-hook-form";
import toast from 'react-hot-toast';
import map from 'lodash/map'

import Heading6 from "@material-tailwind/react/Heading6";

import Small from "@material-tailwind/react/Small";
import Paragraph from "@material-tailwind/react/Paragraph";
import CreatableSelect from 'react-select/creatable';
import { Input, Button } from '@material-tailwind/react';
import { ErrorMessage } from "@hookform/error-message";


const EditForm = ({ id, initialData, setShow }) => {
  const [inputMultiSelect, setInputMultiSelect] = useState('')
  const [categoryField, setCategoryField] = useState('')
  const [inputSelect, setInputSelect] = useState('')

  const [file, setFile] = useState(null)
  const [fileUrl, setFileUrl] = useState(null)

  const { control, handleSubmit, formState: { errors }, setValue } = useForm();

  const fileChange = (e) => {
    setFile(e.target.files[0])
  }

  const upload = () => {
    if (file === null) {
      toast.error('Please, choose a file to upload.')
      return
    }

    const loading = toast.loading('Uploading image...');
    const storageRef = storage.ref();
    const uploadTask = storageRef.child('image/' + file.name).put(file);
    uploadTask.on(firebaseRef.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
      }, (error) => {
        toast.error('Error while uploading.');
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setFileUrl(url);
          toast.dismiss(loading)
        })
      }
    )
  }

  const {
    name,
    servingSize,
    totalCalories,
    totalFat,
    totalCarbo,
    totalProtein,
    totalSodium,
    totalSugar,
    totalCholesterol,
    category,
    imageUrl,
    ingredients: initialIngredients,
  } = initialData

  const ing = []
  map(initialIngredients, i => ing.push(i))
  const [ingredients, setIngredients] = useState(ing)

  useEffect(() => {
    setValue('name', name)
    setValue('totalCalories', totalCalories)
    setValue('servingSize', servingSize)
    setValue('fat', totalFat)
    setValue('carbo', totalCarbo)
    setValue('protein', totalProtein)
    setValue('sodium', totalSodium)
    setValue('sugar', totalSugar)
    setValue('cholesterol', totalCholesterol)
    setCategoryField(category)
    setIngredients(ing)
    setFileUrl(imageUrl)
    // eslint-disable-next-line
  }, [])

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleTagChange = (value) => {
    setIngredients(value);
  }

  const handleTagInputChange = (value) => {
    setInputMultiSelect(value)
  }

  const handleKeyDown = (event) => {
    if (!inputMultiSelect) {
      return;
    }
    switch (event.key) {
      case ',':
        setInputMultiSelect('')
        setIngredients(prevState => [...prevState, createOption(inputMultiSelect)])
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleCatChange = (value) => {
    setCategoryField(value);
  }

  const handleCatInputChange = (value) => {
    setInputSelect(value)
  }

  const onSubmit = async (data) => {
    const categoryToDb = { value: categoryField.value, label: categoryField.label }

    try {
      await firestore.collection("items").doc(id).update({
        ...data,
        ingredients,
        imageUrl: fileUrl,
        category: categoryToDb
      });
      console.log(categoryToDb);
      setShow(false);
      toast.success('Edited successfully!');
      window.location.reload();
    } catch (err) {
      toast.error('Error when editing!');
      console.log(err);
    }
  }

  return (
    <form className="w-full lg:space-y-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <Paragraph>
          Category
        </Paragraph>
        <CreatableSelect
          inputValue={inputSelect}
          onChange={handleCatChange}
          onInputChange={handleCatInputChange}
          placeholder="Put the food category."
          value={categoryField}
        />
        <Paragraph>
          Ingredients
        </Paragraph>
        <CreatableSelect
          inputValue={inputMultiSelect}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={handleTagChange}
          onInputChange={handleTagInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Separate ingredients with a comma..."
          value={ingredients}
        />
        <div className="w-2/3">
        </div>
      </div>
      <div className="lg:grid lg:grid-cols-3 gap-5">
        <div className="lg:col-span-1">
          <Heading6>Add item image</Heading6>
          <div className="space-y-5 lg:space-y-0 lg:flex content-center items-center">
            <input type="file" className="w-full" name="image" onChange={(e) => { fileChange(e) }} />
            <Button size="sm" className="w-full lg:w-auto" type="button" color="pink" onClick={upload}>Upload image</Button>
          </div>
        </div>
        {fileUrl !== null &&
          <div className="lg:col-span-1">
            <img alt="Preview" className="rounded shadow-md object-fit w-full h-full" src={fileUrl} />
          </div>
        }
        <div className="lg:col-span-1">
          <Paragraph>
            Item name
          </Paragraph>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
            rules={{ required: 'This field is required!' }}

          />
          <Small color="red">
            <ErrorMessage errors={errors} name="name" as="span" />
          </Small>
        </div>
        <div className="col-span-1">
          <Paragraph>
            Total calories
          </Paragraph>
          <Controller
            name="totalCalories"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
            rules={{ required: 'This field is required!' }}

          />
          <Small color="red">
            <ErrorMessage errors={errors} name="totalCalories" as="span" />
          </Small>
        </div>
        <div className="lg:col-span-1">
          <Paragraph>
            Serving size
          </Paragraph>
          <Controller
            name="servingSize"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
            rules={{ required: 'This field is required!' }}

          />
          <Small color="red">
            <ErrorMessage errors={errors} name="servingSize" as="span" />
          </Small>
        </div>
        <div className="lg:col-span-1">
          <Paragraph>
            Total fat
          </Paragraph>
          <Controller
            name="fat"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
            rules={{ required: 'This field is required!' }}

          />
          <Small color="red">
            <ErrorMessage errors={errors} name="fat" as="span" />
          </Small>
        </div>
        <div className="lg:col-span-1">
          <Paragraph>
            Total Carbohydrates
          </Paragraph>
          <Controller
            name="carbo"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
            rules={{ required: 'This field is required!' }}

          />
          <Small color="red">
            <ErrorMessage errors={errors} name="carbo" as="span" />
          </Small>
        </div>
        <div className="lg:col-span-1">
          <Paragraph>
            Total Protein
          </Paragraph>
          <Controller
            name="protein"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
            rules={{ required: 'This field is required!' }}
          />
          <Small color="red">
            <ErrorMessage errors={errors} name="protein" as="span" />
          </Small>
        </div>
        <div className="lg:col-span-1">
          <Paragraph>
            Total sodium
          </Paragraph>
          <Controller
            name="sodium"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
          />
        </div>
        <div className="lg:col-span-1">
          <Paragraph>
            Total sugar
          </Paragraph>
          <Controller
            name="sugar"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
          />
        </div>
        <div className="lg:col-span-1">
          <Paragraph>
            Total Cholesterol
          </Paragraph>
          <Controller
            name="cholesterol"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} />}
          />
        </div>
      </div>
      <div className="flex content-center justify-around items-center">
        <Button
          color="pink"
          buttonType="link"
          onClick={() => setShow(false)}
          ripple="dark"
        >
          Close
        </Button>
        <Button type="submit" color="pink">
          Save
        </Button>
      </div>
    </form>
  );
}

export default EditForm;
