import { Switch, Route, Redirect } from 'react-router-dom';
import Landing from 'pages/Landing';
import Login from 'pages/Login';
import Register from 'pages/Register';
import Contact from 'pages/Contact';
import About from 'pages/About';
import Account from 'pages/Account';
import Restaurant from 'pages/Restaurant';
import RestaurantMenu from 'pages/RestaurantMenu';
import Item from 'pages/Item';
import ItemDetails from 'pages/ItemDetails';
import AddCategory from 'pages/AddCategory';
import Categories from 'pages/Categories';

// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';
// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';
import AuthService from './services/auth.service';
import AuthVerify from './services/auth-verify';
import GuardedRoute from './services/guarde-route';

function App () {

  function logOut () {
    AuthService.logout();
  }

  const user = AuthService.getCurrentUser();

  return (
    <>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/admin" component={Login} />
        <Route exact path="/admin/register" component={Register} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />
        <Route path='/restaurant/:id' component={RestaurantMenu} auth={user} />
        <Route path='/restaurant' component={Restaurant} />
        <Route path='/item/:id' component={ItemDetails} />
        <GuardedRoute path='/add' component={Item} auth={user} />
        <GuardedRoute path='/account' component={Account} auth={user} />
        <GuardedRoute path='/add-category' component={AddCategory} auth={user} />
        <GuardedRoute path='/categories' component={Categories} auth={user} />
        <Redirect from="*" to="/" />
      </Switch>
      <AuthVerify logOut={logOut} />
    </>
  );
}

export default App;
