import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
    apiKey: "AIzaSyD5V9ijyIeM3KTnSZ2ejozzqrJ3HFPxgmE",
    authDomain: "diet-app-6eea1.firebaseapp.com",
    projectId: "diet-app-6eea1",
    storageBucket: "diet-app-6eea1.appspot.com",
    messagingSenderId: "995202856941",
    appId: "1:995202856941:web:6ec2a8b82fb6bd4dc999e4"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const firebaseRef = firebase;

firestore.settings({
    timestampsInSnapshots: true
});

export const generateUserDocument = async (user, username) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
        const {
            email,
            displayName,
            photoURL,
            uid,
            emailVerified
        } = user;
        try {
            await userRef.set({
                displayName,
                email,
                photoURL,
                uid,
                emailVerified,
                created: firebase.firestore.Timestamp.fromDate(new Date()),
                ...username,
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
    return getUserDocument(user.uid);
};

export const getUserDocument = async (uid) => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`users/${uid}`).get();
        return {
            uid,
            ...userDocument.data(),
        };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};

export const contactRequestGenerator = async (BusinessName, BusinessEmail, Message, ReceiverID) => {
    let businessDocID = '';

    const requestersRef = await firestore.collection("Requesters").where("BusinessEmail", "==", BusinessEmail);
    const snapshot = await requestersRef.get();

    console.log('querySnapshot size for business email query with Requesters colllection +++', snapshot.size);

    if (snapshot.size) {
        await snapshot.forEach((doc) => {
            businessDocID = doc.id;
            console.log("business email find case +++ id => ", businessDocID, " => data => ", doc.data());
        });
    } else {
        console.log('business email create case +++');
        await firestore.collection("Requesters").add({
            BusinessEmail
        }).then((docRef) => {
            businessDocID = docRef.id;
            console.log("business email created with this id +++", businessDocID);
        })
            .catch((error) => {
                console.error("business email create error +++", error);
            });
    }

    if (businessDocID) {
        const requestsQuery = await firestore.collection("requests").where("BusinessEmail", "==", BusinessEmail);
        const snapshot = await requestsQuery.get();

        console.log('querySnapshot size for business email query with requests colllection +++', snapshot.size);

        if (snapshot.size) {
            snapshot.forEach(async (doc) => {
                console.log("business email find case for requests collection +++ id => ", doc.id, " => data => ", doc.data());
                const requestsRef = firestore.doc(`requests/${doc.id}`);
                try {
                    await requestsRef.update({
                        BusinessName,
                    });

                    // create message
                    firestore.doc(`requests/${doc.id}`).collection('threads').add({
                        Content: Message,
                        Created: firebase.firestore.Timestamp.fromDate(new Date()),
                        SenderID: businessDocID,
                        BusinessName
                    }).then((docRef) => {
                        console.log("requests threads new document written with id: ", docRef.id);
                    })
                        .catch((error) => {
                            console.error("Error adding requests threads new document: ", error);
                        });
                } catch (error) {
                    console.error("Error update requests document", error);
                }
            });
        } else {
            console.log('create new requests document case +++');
            firestore.collection("requests").add({
                BusinessName,
                BusinessEmail,
                UserID: ReceiverID,
                IsValid: false,
                InfluencerSent: false,
                TimePosted: firebase.firestore.Timestamp.fromDate(new Date())
            }).then((docRef) => {
                console.log("new requests document written with id: ", docRef.id);
                // create message
                firestore.doc(`requests/${docRef.id}`).collection('threads').add({
                    content: Message,
                    created: firebase.firestore.Timestamp.fromDate(new Date()),
                    senderID: businessDocID,
                    BusinessName
                }).then((docRef) => {
                    console.log("requests threads new document written with id: ", docRef.id);
                })
                    .catch((error) => {
                        console.error("Error adding requests threads new document: ", error);
                    });
            })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
        }
    }
};

export const getImageUrl = async (path) => {
    if (!path) return null;
    try {
        const pathReference = await storage.ref(path);
        const url = await pathReference.getDownloadURL();
        console.log('image url +++', url);
        return url;
    } catch (error) {
        console.error("Error fetching image url", error);
    }
};