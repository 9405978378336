import { useState } from 'react';
import H3 from '@material-tailwind/react/Heading3';
import Paragraph from '@material-tailwind/react/Paragraph';
import Input from '@material-tailwind/react/Input';
import Textarea from '@material-tailwind/react/Textarea';
import Button from '@material-tailwind/react/Button';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import DefaultNavbar from 'components/DefaultNavbar';
import SimpleFooter from 'components/SimpleFooter';
import Page from 'components/login/Page';
import config from '../config';
import { send } from 'emailjs-com';
import toast from 'react-hot-toast';

export default function Contact() {

    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: config.TO_NAME,
        message: '',
        reply_to: '',
    });

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        console.log('form +++', toSend);
        send(
            config.SERVICE_ID,
            config.TEMPLATE_ID,
            toSend,
            config.USER_ID
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            if (response.status === 200 && response.text === 'OK') {
                setToSend({
                    from_name: '',
                    to_name: config.TO_NAME,
                    message: '',
                    reply_to: '',
                });
                toast.success('message sent successfully!');
            } else {
                toast.error('sorry, try again later!');
            }
        }).catch((err) => {
            console.log('FAILED...', err);
            toast.error('try again later!');
        });
    };

    return (
        <Page>
            <DefaultNavbar />
            <div className="ml-5 mr-5">
                <Card>
                    <CardBody>
                        <section className="relative">
                            <div className="flex flex-wrap justify-center mt-24">
                                <div className="w-full lg:w-8/12 px-4">
                                    <div className="relative mb-6">
                                        <div className="flex-auto p-5">
                                            <div className="w-full text-center">
                                                <H3 color="gray">Have any questions or comments?</H3>
                                                <Paragraph color="blueGray">
                                                  Feel free to reach out and I will do my best to get back to you as soon as possible.
                                                </Paragraph>
                                            </div>
                                            <form onSubmit={(e) => onSubmit(e)}>
                                                <div className="flex gap-8 mt-16 mb-12">
                                                    <Input
                                                        type="text"
                                                        placeholder="Full Name"
                                                        color="lightBlue"
                                                        name="from_name"
                                                        onChange={(e) => handleChange(e)}
                                                        value={toSend.from_name}
                                                        required
                                                    />
                                                    <Input
                                                        type="email"
                                                        placeholder="Email"
                                                        color="lightBlue"
                                                        name="reply_to"
                                                        onChange={(e) => handleChange(e)}
                                                        value={toSend.reply_to}
                                                        required
                                                    />
                                                </div>

                                                <Textarea
                                                    color="lightBlue"
                                                    placeholder="Message"
                                                    name="message"
                                                    onChange={(e) => handleChange(e)}
                                                    value={toSend.message}
                                                    required
                                                />

                                                <div className="flex justify-center mt-10">
                                                    <Button color="lightBlue" ripple="light">
                                                        Send Message
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </CardBody>
                </Card>
            </div>
            <SimpleFooter />
        </Page>
    );
}
