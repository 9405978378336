import React from 'react';

import { firestore } from '../../firebase';
// import AuthService from '../../services/auth.service';
import { useForm, Controller } from "react-hook-form";

import Small from "@material-tailwind/react/Small";
import Heading6 from "@material-tailwind/react/Heading6";
// import CreatableSelect from 'react-select/creatable';
import { Input, Button } from '@material-tailwind/react';
import { ErrorMessage } from "@hookform/error-message";

import toast from 'react-hot-toast';

const EditCategoryForm = ({ id, initialData, setShow }) => {

  const { control, handleSubmit, formState: { errors } } = useForm();



  const onSubmit = async (data) => {
    //console.log(data, id)
    try {
      await firestore.collection("category").doc(id).update({
        name: data.name
      });
      setShow(false);
      toast.success('updated successfully!');
      window.location.reload();
    } catch (err) {
      toast.error('Error when updating!');
      console.log(err);
    }
  }



  return (
    <form className="w-full space-y-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-5 lg:space-y-0 lg:grid lg:grid-cols-3 gap-5">
        <div className="col-span-3">
          <Heading6>
            Category Name
          </Heading6>
          <Controller
            name="name"
            control={control}
            defaultValue={initialData}
            render={({ field }) => <Input {...field} />}
            rules={{ required: 'This field is required!' }}
          />
          <Small color="red">
            <ErrorMessage errors={errors} name="name" as="span" />
          </Small>
        </div>
        <div className="col-span-1">
        </div>
        <div className="flex justify-center">
          <Button size="lg" type="submit" className="place-center" color="pink">
            Update Category
          </Button>
        </div>
      </div>
    </form>
  );
}

export default EditCategoryForm;
