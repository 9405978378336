import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom'

import Card from "@material-tailwind/react/Card";
import CardImage from "@material-tailwind/react/CardImage";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H6 from "@material-tailwind/react/Heading6";
import Paragraph from "@material-tailwind/react/Paragraph";
import Popover from "@material-tailwind/react/Popover";
import PopoverContainer from "@material-tailwind/react/PopoverContainer";
import PopoverHeader from "@material-tailwind/react/PopoverHeader";
import PopoverBody from "@material-tailwind/react/PopoverBody";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";

import CustomModal from './CustomModal'

import { map, size } from 'lodash';

const ItemCard = ({
  id,
  name,
  servingSize,
  totalCalories,
  totalFat,
  totalCarbo,
  totalProtein,
  imageUrl,
  ingredients,
  category = { label: '', value: '' },
  totalSodium = null,
  totalSugar = null,
  totalCholesterol = null,
  isOwner = false }) => {

  const [expand, setExpand] = useState(false)
  const [show, setShow] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const buttonRef = useRef();

  return (
    <div className="lg:col-span-1 py-5">
      <CustomModal show={show} setShow={setShow} isEditing={isEditing} isDeleting={isDeleting} data={
        {
          id,
          name,
          servingSize,
          totalCalories,
          totalFat,
          totalCarbo,
          totalProtein,
          ingredients,
          totalSodium,
          totalSugar,
          totalCholesterol,
          category,
          imageUrl
        }} />
      <Card>
        <div>
          <Link to={`/item/${id}`}>
            {
              imageUrl === null &&
              <CardImage
                src={'/placeholder.png'}
                alt="Card Image"
                className="h-52"
              />
            }
            {
              imageUrl !== null &&
              <CardImage
                src={imageUrl}
                alt="Card Image"
                className="h-52"
              />
            }
          </Link>
          <div
            className="flex space-x-2 content-center justify-end py-2"
          >
            <Button
              color="pink"
              buttonType="filled"
              size="sm"
              rounded={false}
              block={false}
              iconOnly={true}
              ripple="light"
              ref={buttonRef}
              className={`w-24 ${size(ingredients) === 0 ? 'cursor-not-allowed' : ''}`}
            >
              <span style={{ fontSize: '11px' }}>Ingredients</span>
            </Button>
            <Popover placement="left" className="w-full h-full pl-5" ref={buttonRef}>
              <PopoverContainer>
                <PopoverHeader>Ingredients</PopoverHeader>
                <PopoverBody>
                  {map(ingredients, i => <p className="capitalize">{i.value}</p>)}
                  {size(ingredients) === 0 && <p> No ingredients found</p>}
                </PopoverBody>
              </PopoverContainer>
            </Popover>
            { localStorage.getItem("user") &&
              <>
                <Button
                  color="pink"
                  buttonType="filled"
                  size="sm"
                  rounded={false}
                  block={false}
                  iconOnly={true}
                  ripple="light"
                  onClick={() => { setIsDeleting(true); setIsEditing(false); setShow(true); }}
                >
                  <Icon family="font-awesome"
                    name={'fas fa-trash'}
                  />
                </Button>
                <Button
                  color="pink"
                  buttonType="filled"
                  size="sm"
                  rounded={false}
                  block={false}
                  iconOnly={true}
                  ripple="light"
                  onClick={() => { setIsDeleting(false); setIsEditing(true); setShow(true); }}
                >
                  <Icon family="font-awesome"
                    name={'fas fa-pen'}
                  />
                </Button>
              </>
            }
          </div>
        </div>
        <CardBody>
          <H6 color="gray">{name}</H6>
          <Paragraph color="gray">
            Serving size: {servingSize}
          </Paragraph>
          <Paragraph color="gray">
            Category: {category.value}
          </Paragraph>
          <Paragraph color="gray">
            Total Calories: {totalCalories}
          </Paragraph>
          <Paragraph color="gray">
            Total Fat (g): {totalFat}
          </Paragraph>
          <Paragraph color="gray">
            Total Carbs (g): {totalCarbo}
          </Paragraph>
          <Paragraph color="gray">
            Total Protein (g): {totalProtein}
          </Paragraph>
          {totalSodium && expand &&
            <Paragraph color="gray">
              Total Sodium (mg): {totalSodium}
            </Paragraph>
          }
          {totalSugar && expand &&
            <Paragraph color="gray">
              Total Sugar (g): {totalSugar}
            </Paragraph>
          }
          {totalCholesterol && expand &&
            <Paragraph color="gray">
              Total Cholesterol (mg): {totalCholesterol}
            </Paragraph>
          }
        </CardBody>

        <CardFooter>
          {!expand &&
            <Button color="pink" className="w-full" size="lg" ripple="light" onClick={() => { setExpand(true) }}>
              Read More
            </Button>
          }
          {expand &&
            <Button color="pink" className="w-full" size="lg" ripple="light" onClick={() => { setExpand(false) }}>
              Show less
            </Button>
          }
        </CardFooter>
      </Card >
    </div >
  );
}

export default ItemCard;