import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class AuthVerify extends Component {
    constructor(props) {
        super(props);
        props.history.listen(() => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (!user) {
                props.logOut();
            }
        });
    }

    render() {
        return <div></div>;
    }
}

export default withRouter(AuthVerify);