import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Content from 'components/item/Content';

export default function Item () {
  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Content />
      </main>
      <DefaultFooter />
    </>
  );
}
