import { Heading4 } from '@material-tailwind/react';
import CategoryList from './CategoryList';

const ViewCategoryContent = () => {
    return (
        <section className="pt-20 pb-48">
            <div className="container max-w-7xl mx-auto px-4">
                <div className="flex justify-center">
                    <Heading4>All Categories</Heading4>
                </div>
                <div className="relative mx-auto flex w-full justify-between flex-wrap items-stretch mb-3 mt-7">
                    <div className="w-full flex" style={{width: '60%', marginLeft: 'auto', marginRight: 'auto'}}>
                        <div className="p-5 lg:p-10 rounded-lg shadow-lg border-opacity-20 border-gray-800 border">
                        <CategoryList />
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
    )
}

export default ViewCategoryContent;